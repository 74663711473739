export const deiterateColors = [
  '#FFD325',
  '#F6961D',
  '#07B824',
  '#D8090F',
  '#8B08AF',
  '#0099F1',
  '#F26522',
  '#e62229',
];
